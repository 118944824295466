/*.appTitle {
  margin-bottom: 0.8112rem;
}*/

.appTitle a {
  display: block;
  max-width: 560px;
}

.activePage {
  font-weight: 600;
  padding: 0 1px;
  transition: color 0.5s;
}
