/* Remove font-face rules AND FONTS when final font suite is determined */

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('../../fonts/cooperhewitt-book-webfont.woff2') format('woff2'),
       url('../../fonts/cooperhewitt-book-webfont.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('../../fonts/cooperhewitt-semibold-webfont.woff2') format('woff2'),
       url('../../fonts/cooperhewitt-semibold-webfont.woff') format('woff');
  font-weight: 600;
}

body {
  color: #3d3d3d; /* Black Olive */
  font-family: 'Cooper Hewitt';
  height: calc(100vh - 7px);
  line-height: 1.25;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 5px 8px 2px 8px;
  text-rendering: optimizeLegibility;
  transition: background-color 0.5s;
}

#root, .wrapAll {
  height: 100%;
}

.wrapAll {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

a {
  background-color: #3d3d3d; /* Black Olive */
  font-weight: 600;
  padding: 0 1px;
  text-decoration: none;
  transition: color 0.5s;
}

a:visited {
  color: inherit; /* Placeholder until color palette is set */
  /*opacity: 0.75;*/
}

.homeBG {
  background-color: #32ff7e; /* Spring Green */
}

.homeBG a, .homeBG .appTitle a {
  color: #32ff7e; /* Spring Green */
  transition: color 0.5s;
}

.projectsBG {
  background-color: #7efff5; /* Electric Blue */
}

.projectsBG a, .projectsBG .appTitle a {
  color: #7efff5; /* Electric Blue */
  transition: color 0.5s;
}

.aboutBG {
  background-color: #fff200; /* Yellow Rose */
}

.aboutBG a, .aboutBG .appTitle a {
  color: #fff200; /* Yellow Rose */
  transition: color 0.5s;
}

.contactBG {
  background-color: #ffb8b8; /* Melon */
}

.contactBG a, .contactBG .appTitle a {
  color: #ffb8b8; /* Melon */
  transition: color 0.5s;
}

.navList {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 0.875rem 0;
  padding-left: 0;
}

.navList li:not(:last-child) {
  padding-right: 0.55rem;
}

.navList li a, .navList li .activePage {
  border-bottom: 2px solid #3d3d3d;
  border-left: 2px solid #3d3d3d;
  border-right: 2px solid #3d3d3d;
  display: inline-block;
  padding: 3px 2.5px 0px 2.5px;
}

h1, h2, h3, h4 {
	/*font-feature-settings: 'ss02','ss03';*/
  margin: 0;
}

h1, h2 {
  font-size: 2.25rem;
  font-weight: 600;
}

h3, h4 {
  font-size: 1.125rem;
  font-weight: inherit;
}

p {
  /*font-family: 'Vremena Grotesk';*/
  font-weight: 400;
}

section {
  max-width: 560px;
}

.sectionTitle {
  margin-bottom: 0.53125rem;
}

.outerSection {
  margin-bottom: 1.3125rem;
}

footer {
  padding-bottom: 2px;
}
